<template>
  <ModalEditCaasTemplate
    :template="rowItems"
    :show-modal="showModalEditTemplate"
    :templates-modal="templatesTable"
    @close="closeEditModal"
    @click.prevent.stop
  />

  <ModalDeleteCaasDocument
    :item="rowItems"
    :show-modal="showDeleteCaasDocumentModal"
    :documents-modal="documentsTable"
    :templates-modal="templatesTable"
    @close="closeDelete"
    @click.prevent.stop
  />
  <ModalRenameCaasDocument
    :show-modal="showRenameCaasDocumentModal"
    :documents-modal="documentsTable"
    :templates-modal="templatesTable"
    :item="rowItems"
    @close="closeRename"
    @click.prevent.stop
  />
  <tr
    class="w-full rounded mt-2 bg-neutral-50 relative items-center"
    :class="{
      'cursor-pointer': documentsTable,
      '!bg-white': whiteBg
    }"
    :data-cy="`cg-${rowItems.id}`"
    @click="onClick"
  >
    <TableCell>
      <TextSmall medium>
        {{ rowItems.attributes.title }}
      </TextSmall>
    </TableCell>
    <TableCell>
      <OutputText truncate :text="createdBy" />
    </TableCell>
    <TableCell>
      <OutputText
        truncate
        :text="formateDate(rowItems.attributes.created_at)"
      />
    </TableCell>
    <TableCell v-if="documentsTable">
      <OutputText
        truncate
        :text="rowItems.attributes.raw_content?.split(' ').length || 0"
      />
    </TableCell>
    <TableCell
      overflowVisible
      class="relative"
      :class="{ 'flex justify-between items-center': templatesTable }"
    >
      <div
        v-if="templatesTable && route.name === 'caasTemplates'"
        :data-cy="`editTemp-${rowItems.id}`"
        class="bg-white flex gap-2 items-center border border-neutral-80 rounded-lg py-2 px-3 cursor-pointer hover:bg-primary-50 [&>*]:hover:!text-primary-100 hover:border-primary-100"
        @click="handleEditTemplate"
      >
        <font-awesome-icon icon="fa-solid fa-pen" />
        <TextSmall>
          {{ $t('shared.content_generation.edit_template') }}
        </TextSmall>
      </div>
      <slot name="editBtn" />
      <slot name="applyTemplateBtn" />
      <ButtonEditCard
        :data-cy="`edit-${rowItems.id}`"
        class="!relative justify-self-end"
      >
        <template #option>
          <span
            v-if="allowRename"
            data-cy="toggle_rename"
            @click.prevent.stop="handleRenameClick"
            >{{ $t('shared.label.rename') }}</span
          >
          <span
            v-if="allowEditTemplate"
            @click="$emit('editTemplate', rowItems)"
            >{{ $t('shared.label.edit') }}</span
          >
          <span v-if="allowExportTemplate">{{
            $t('shared.label.export')
          }}</span>
          <span
            class="!text-error-100"
            data-cy="toggle_delete"
            @click.prevent.stop="handleDeleteClick"
            >{{ $t('shared.button.delete') }}</span
          >
        </template>
      </ButtonEditCard>
    </TableCell>
  </tr>
</template>
<script setup>
import ButtonEditCard from '@/components/shared/ButtonEditCard'
import TextSmall from '@/components/shared/font/text/TextSmall'
import ModalDeleteCaasDocument from '@/components/shared/modals/ModalDeleteCaasDocument'
import ModalEditCaasTemplate from '@/components/shared/modals/ModalEditCaasTemplate'
import ModalRenameCaasDocument from '@/components/shared/modals/ModalRenameCaasDocument'
import OutputText from '@/components/shared/output/OutputText'
import TableCell from '@/components/shared/table/TableCell'
import i18n from '@/i18n'
import moment from 'moment'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

const store = useStore()
const $t = i18n.t
const router = useRouter()
const route = useRoute()

const showDeleteCaasDocumentModal = ref(false)
const showRenameCaasDocumentModal = ref(false)
const showModalEditTemplate = ref(false)

const emit = defineEmits(['editTemplate'])

const props = defineProps({
  rowItems: Object,
  documentsTable: {
    type: Boolean,
    default: false
  },
  templatesTable: {
    type: Boolean,
    default: false
  },
  allowRename: {
    type: Boolean,
    default: false
  },
  allowExportTemplate: {
    type: Boolean,
    default: false
  },
  allowEditTemplate: {
    type: Boolean,
    default: false
  },
  whiteBg: Boolean
})

const currentUser = computed(() => store.state.workspace.currentUser)

const createdBy = computed(
  () =>
    `${currentUser.value.attributes.first_name} ${
      currentUser.value.attributes.last_name
        ? currentUser.value.attributes.last_name[0] + '.'
        : ''
    }`
)

const stopLoader = () => store.dispatch('loading/stopLoader')
const getDocument = (payload) =>
  store.dispatch('contentGeneration/getDocument', payload)

onMounted(() => {
  stopLoader()
})

function handleRenameClick() {
  showRenameCaasDocumentModal.value = true
}
function closeRename() {
  showRenameCaasDocumentModal.value = false
}
function handleDeleteClick() {
  showDeleteCaasDocumentModal.value = true
}
function handleEditTemplate() {
  showModalEditTemplate.value = true
}
function closeEditModal() {
  showModalEditTemplate.value = false
}
function closeDelete() {
  showDeleteCaasDocumentModal.value = false
}
async function onClick() {
  if (props.documentsTable) {
    await getDocument(props.rowItems?.id)
    router.push({
      name: 'contentGenerationV2'
    })
  }
}
function formateDate(date) {
  if (!date) return '-'
  return moment(date).format('DD.MM.YYYY')
}
</script>
